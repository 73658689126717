<template>
  <div class="vh-customer-componets-editor-preview">
    <component
      :is="componetPreview"
      :units.sync="currentInfo.components"
      :menuInfo="currentInfo"
      :uuid="currentInfo.uuid"
      :pre="pre"
    ></component>
  </div>
</template>
<script>
  import eventsType from '../EventConts';
  import { TypeMaps } from './componentMap';

  // 预览组件区域
  const componentCustomer = () => import('./components-view/component-customer');
  const componentDoc = () => import('./components-view/component-doc');
  const componentChat = () => import('./components-view/component-chat');
  const componentDes = () => import('./components-view/component-des');
  const componentGoods = () => import('./components-view/component-goods');
  const componentRecommend = () => import('./components-view/component-recommend');
  const componentDownload = () => import('./components-view/component-download');
  const componentHixianchang = () => import('./components-view/component-hixianchang'); //hi现场游戏互动
  const componentMoments = () => import('./components-view/component-moments'); // 精彩时刻
  const componentSummary = () => import('./components-view/component-summary'); // 概要总结

  export default {
    props: {
      pre: {
        required: true,
        default: 1
      }
    },
    data() {
      return {
        currentInfo: {}
      };
    },

    components: {
      componentCustomer,
      componentDoc,
      componentChat,
      componentDes,
      componentGoods,
      componentRecommend,
      componentDownload,
      componentHixianchang,
      componentMoments,
      componentSummary
    },

    computed: {
      type: function () {
        console.log('this.currentInfo.type:', this.currentInfo.type);
        return this.currentInfo.type;
      },

      componetPreview: function () {
        console.log('TypeMaps[this.type]:', TypeMaps[this.type]);
        return TypeMaps[this.type];
      }
    },

    created() {
      this.$EventBus.$on(eventsType.INIT_MENU_INFO, this.initInfo);
    },

    methods: {
      initInfo(menuInfo) {
        this.currentInfo = menuInfo;
        if (this.type == 1 && this.currentInfo.components?.length > 0) {
          this.currentInfo.components = this.currentInfo.components.map(item => {
            return {
              ...item
            };
          });
        }
      },

      validateALL(component_id) {
        return this.$parent.validateCanAdd(component_id);
      }
    }
  };
</script>
<style lang="less" scoped>
  .vh-customer-componets-editor-preview {
    height: 100%;
  }
</style>
