<template>
  <div>
    <section class="layout__main">
      <nav
        class="layout__left"
        :class="{
          hideSidebar: !openSidebar,
          openSidebar: openSidebar,
          withoutAnimation: false
        }"
      >
        <sidebar class="sidebar-container" />
      </nav>
      <section
        class="layout__right"
        :class="{
          hideSidebar: !openSidebar,
          openSidebar: openSidebar,
          withoutAnimation: false
        }"
      >
        <header class="header__nav" :class="showShadow ? 'show-shadow' : ''">
          <navbar />
        </header>
        <!-- 系统通知 -->
        <sys-banner />
        <!-- 主体内容 -->
        <div class="main_box" id="main_box" ref="mainBox">
          <section
            class="section__main"
            :class="[isCms ? 'section__main-cms' : '', isDataHub ? 'section__main-datahub' : '']"
          >
            <!-- 面包屑导航 -->
            <breadcrumb class="breadcrumb-container" v-if="$route.meta.project == 'webcasting'" />
            <!-- 具体内容区 -->
            <app-main />
          </section>
        </div>
      </section>
    </section>
  </div>
</template>

<script>
  import { throttle } from 'lodash';
  import { Navbar, Sidebar, AppMain, SysBanner } from './components';
  import Breadcrumb from './components/Breadcrumb/index.vue';
  export default {
    components: { Sidebar, Navbar, AppMain, SysBanner, Breadcrumb },
    data() {
      return {
        openSidebar: true,
        showShadow: false
      };
    },
    computed: {
      isDataHub() {
        return this.$route.path.indexOf('datahub') > -1;
      },
      isCms() {
        return this.$route.path.indexOf('cms') > -1;
      }
    },
    watch: {
      '$route.path': {
        handler(newVal, oldVal) {
          if (newVal !== oldVal) {
            this.$refs.mainBox.scrollTo(0, 0);
          }
        }
      }
    },
    mounted() {
      this.$EventBus.$on('hamburger', status => {
        this.openSidebar = !!status;
      });
      this.$nextTick(() => {
        document.getElementById('main_box').addEventListener('scroll', this.handleScroll); // 监听（绑定）滚轮滚动事件
      });
    },
    beforeDestroy() {
      this.$EventBus.$off('hamburger');
      const mainBox = document.getElementById('main_box');
      if (mainBox) {
        mainBox.removeEventListener('scroll', this.handleScroll);
      }
    },
    methods: {
      handleScroll: throttle(function (event) {
        let scrollHeight = document.getElementById('main_box').scrollTop; //滚动高度
        if (scrollHeight > 40) {
          this.showShadow = true;
        } else {
          this.showShadow = false;
        }
      }, 300)
    }
  };
</script>

<style lang="less" scoped>
  @color_f7: #f7f7f7;
  @color_1A: #1a1a1a;
  @color_ff: #ffffff;
  /*头部*/
  .header__nav {
    background: @color_ff;
    display: block;
    overflow: hidden;
    z-index: 100;
    height: 56px;
    position: relative;
    border-bottom: 1px solid #f0f0f0;
    &.show-shadow {
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.05);
    }
  }
  .main_box {
    width: 100%;
    height: calc(100% - 56px);
    overflow: auto;
  }
  /*主体*/
  .layout__left {
    float: left;
    font-size: 20px;
    background: @color_1A;
    transition: all 0.3s;
    &:before {
      content: '';
      display: block;
      height: 1px;
      background-color: #fff;
    }
    &.hideSidebar {
      width: 84px;
    }
    &.openSidebar {
      width: 224px;
    }
  }
  .layout__right {
    overflow: hidden;
    background: @color_f7;
    height: 100vh;
    position: relative;
  }
  .breadcrumb-container {
    text-align: left;
    height: 20px;
    line-height: 20px;
    margin-bottom: 17px;
  }
  .section__main {
    padding-top: 6px;
    width: 1020px;
    min-width: 1024px;
    /*min-height: 650px;*/
    min-height: 100%;
    height: auto;
    margin: 0 auto;
    padding-bottom: 40px;
    &-cms {
      width: 100%;
      min-width: none;
      margin: 0;

      .breadcrumb-container {
        max-width: 1024px;
        margin: 0 auto;
      }
    }
    &-datahub {
      padding-top: 24px;
      min-width: 1076px;
      width: initial;
      margin: 0 45px;
      padding-bottom: 40px;
    }
  }
  @media (min-width: 1920px) {
    .section__main {
      width: 1374px;
      min-width: 1374px;
      &-cms {
        width: 100%;
        min-width: none;
        margin: 0;
        // padding: 64px 0 0;
      }
      &-datahub {
        width: 1628px;
        margin: 0 auto;
      }
    }
  }
</style>
