var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',{staticClass:"layout__main"},[_c('nav',{staticClass:"layout__left",class:{
        hideSidebar: !_vm.openSidebar,
        openSidebar: _vm.openSidebar,
        withoutAnimation: false
      }},[_c('sidebar',{staticClass:"sidebar-container"})],1),_c('section',{staticClass:"layout__right",class:{
        hideSidebar: !_vm.openSidebar,
        openSidebar: _vm.openSidebar,
        withoutAnimation: false
      }},[_c('header',{staticClass:"header__nav",class:_vm.showShadow ? 'show-shadow' : ''},[_c('navbar')],1),_c('sys-banner'),_c('div',{ref:"mainBox",staticClass:"main_box",attrs:{"id":"main_box"}},[_c('section',{staticClass:"section__main",class:[_vm.isCms ? 'section__main-cms' : '', _vm.isDataHub ? 'section__main-datahub' : '']},[(_vm.$route.meta.project == 'webcasting')?_c('breadcrumb',{staticClass:"breadcrumb-container"}):_vm._e(),_c('app-main')],1)])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }