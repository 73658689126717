<template>
  <!-- 文档资料库 -->
  <VhallDialog
    title="文档列表"
    :before-close="handleClose"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    width="800px"
  >
    <div class="word-list" style="min-height: 478px">
      <div v-if="isSearch || total">
        <div class="header-box">
          <div class="upload-box">
            <vh-button class="upload-btn" type="primary" round @click="toUpload" size="medium">
              上传
            </vh-button>
            <vh-tooltip effect="dark" placement="right" v-tooltipMove>
              <div slot="content">
                1.支持的文档格式：doc/docx，xls/xlsx，ppt/pptx，pdf，jpeg/jpg，png，bmp
                <br />
                2.上传的文档内容不能超过500页，不超过300M，文档名称不能超过64个字
              </div>
              <i class="iconfont-v3 saasicon_help_m icon__max_show"></i>
            </vh-tooltip>
          </div>

          <vh-input
            class="head-btn search-tag"
            placeholder="请输入文档名称"
            v-clearEmoij
            v-model="formParams.keyword"
            @keyup.enter.native="searchHandle"
            clearable
            @clear="searchHandle"
          >
            <i class="vh-icon-search vh-input__icon" slot="prefix" @click="searchHandle"></i>
          </vh-input>
        </div>

        <!-- 表格 -->
        <vh-table
          ref="elTable"
          :data="dialogTableList"
          tooltip-effect="dark"
          style="width: 100%"
          height="336px"
          v-loadMore="moreLoadData"
          :header-cell-style="{ background: '#f7f7f7', color: '#666', height: '56px' }"
          @selection-change="changeDialogCheck"
          @select-all="checkAllRow"
        >
          <!-- <vh-table-column
          :reserve-selection="true"
          type="selection"
          width="55"
          align="left"
        /> -->
          <vh-table-column type="selection" width="55" align="left" />
          <vh-table-column label="文档名称" width="200" show-overflow-tooltip>
            <template slot-scope="scope">
              <p class="text">
                <icon class="word-status" :icon-class="scope.row.ext | wordStatusCss"></icon>
                {{ scope.row.file_name }}
              </p>
            </template>
          </vh-table-column>
          <vh-table-column label="创建时间" prop="created_at"></vh-table-column>
          <vh-table-column label="页码" prop="page" width="84px"></vh-table-column>
          <vh-table-column label="进度" width="220px">
            <template slot-scope="scope">
              <!--  <span v-if="!scope.row.transform_schedule_str">{{scope.row.isUpload ? '上传' : ''}}{{scope.row.codeProcess}}%</span>
            <vh-progress v-if="!scope.row.transform_schedule_str" :show-text=false status="success" :percentage="scope.row.codeProcess"></vh-progress>
            <div v-else class="progressBox">
              <span :class="[scope.row.fileStatusCss, 'statusTag']">{{scope.row.fileStatusStr}}<span><icon v-if="Number(scope.row.showEventType) === 5 || Number(scope.row.showEventType) === 7" icon-class="saasicon-reset"></icon></span></span>
            </div> -->
              <div v-if="!scope.row.transform_schedule_str" class="progressBox">
                <vh-progress :percentage="scope.row.codeProcess"></vh-progress>
              </div>
              <div v-else class="progressBox">
                <!-- 样式变化 -->
                <template v-for="(item, ins) of scope.row.transform_schedule_str.split('<br/>')">
                  <span
                    :class="[
                      scope.row.fileStatusCss == 'statusfailer'
                        ? scope.row.fileStatusCss + ins
                        : scope.row.fileStatusCss,
                      'statusTag'
                    ]"
                    :key="ins"
                  >
                    {{ item }}
                  </span>
                  <br />
                </template>
              </div>
            </template>
          </vh-table-column>
          <div slot="empty">
            <null-page :nullType="'search'" v-if="!total" :height="60"></null-page>
          </div>
        </vh-table>
      </div>
      <null-page
        :nullType="'nullData'"
        v-else-if="total === 0"
        text="您还没有文件，快来上传吧"
        :height="60"
      >
        <vh-button type="primary" round @click="toUpload" size="medium">上传</vh-button>
        <div class="upload_notice">
          <p>
            1.支持的文档格式：doc/docx，xls/xlsx，ppt/pptx，pdf，jpeg/jpg，png，bmp
            <br />
            2.上传的文档内容不能超过500页，不超过300M，文档名称不能超过64个字
          </p>
        </div>
      </null-page>
      <div class="btn-center" v-if="isSearch || total">
        <span class="select-option">
          当前选中
          <b>{{ dialogMulti.length }}</b>
          个文档（最多选择{{ maxCheckedNum }}个文件）
        </span>
        <vh-button type="info" plain round size="medium" @click="cancelCheckHandle">取消</vh-button>
        <vh-button
          v-preventReClick
          type="primary"
          round
          size="medium"
          @click="saveCheckHandle"
          :disabled="!(dialogMulti && dialogMulti.length > 0)"
        >
          确定
        </vh-button>
      </div>
    </div>
  </VhallDialog>
</template>

<script>
  import NullPage from '@/views/PlatformModule/Error/nullPage';
  import { isEmbed, jump } from '../../utils/utils';

  export default {
    name: 'selectWord.vue',
    components: {
      NullPage
    },
    data() {
      return {
        dialogVisible: false,
        total: '',
        isSearch: false,
        nullText: 'search',
        isCheckAll: false,
        dialogTableList: [],
        dialogMulti: [],
        dialogMultiList: [],
        tableColumn: [
          {
            label: '文档名称',
            key: 'file_name'
          },
          {
            label: '上传时间',
            key: 'created_at'
          },
          {
            label: '页码',
            key: 'page'
          },
          {
            label: '进度',
            key: 'transform_schedule_str'
          }
        ],
        formParams: {
          keyword: ''
        },
        pageInfo: {
          pos: 0,
          limit: 50,
          pageNum: 1
        },
        totalPages: 0
      };
    },
    props: {
      maxCheckedNum: {
        required: false,
        type: Number,
        default: 0
      },
      selected: {
        default: []
      },
      get_no_trans: {
        default: 1 //1:获取转码完成 0:或不传获取全部 默认为
      }
    },
    watch: {
      dialogVisible() {
        if (this.dialogVisible) {
          this.initComp();
        }
      }
    },
    methods: {
      toUpload() {
        this.$vhConfirm('上传资源会离开当前页面，将丢失已编辑信息，是否离开？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          customClass: 'zdy-message-box',
          lockScroll: false,
          roundButton: true,
          cancelButtonClass: 'zdy-confirm-cancel'
        })
          .then(() => {
            jump({
              path: `${isEmbed() ? '/embed' : ''}/live/word/${this.$route.params.str}`
            });
          })
          .catch(() => {});
      },
      handleClose(done) {
        this.pageInfo.pageNum = 1;
        done();
      },
      moreLoadData() {
        if (this.pageInfo.pageNum >= this.totalPages) {
          return false;
        }
        this.pageInfo.pageNum++;
        this.pageInfo.pos = parseInt((this.pageInfo.pageNum - 1) * this.pageInfo.limit);
        this.getDialogTableList();
      },
      // 获取资料库-弹出框内容
      getDialogTableList() {
        let params = {
          keyword: this.formParams.keyword,
          type: 1,
          webinar_id: this.$route.params.str,
          pos: this.pageInfo.pos,
          limit: this.pageInfo.limit,
          get_no_trans: this.get_no_trans
        };
        this.isSearch = this.formParams.keyword ? true : false;
        this.$fetch('getWebinarWordList', this.$params(params))
          .then(res => {
            if (res && res.code === 200) {
              let list = res.data.list;
              list.map(item => {
                /*
              converted_page: "0" // 动态页数
              converted_page_jpeg: "1" // 静态页数
              document_id: "01b17b82" // 文档ID
              page: "1" // 文档总页数
              status: "200" // 动态转换状态 0待转换 100转换中 200完成 500失败
              status_jpeg: "200" // 静态转换状态 0待转换 100转换中 200完成 500失败
            */
                const statusJpeg = item.status_jpeg * 1;
                const status = item.status * 1;
                if (statusJpeg === 0) {
                  item.showEventType = 0;
                  item.fileStatusCss = 'wating';
                  item.fileStatusStr = '等待转码';
                  item.transform_schedule_str = `等待转码`;
                } else if (statusJpeg === 100) {
                  item.showEventType = 1;
                  item.transform_schedule_str = ``; // 静态转码中
                  let _percent = (parseInt(item.converted_page_jpeg) / parseInt(item.page)) * 100;
                  item.codeProcess = (_percent + '').substr(0, 4);
                } else if (statusJpeg === 200) {
                  if (/pptx?/.test(item.ext)) {
                    // 如果是ppt or pptx
                    if (status === 0) {
                      item.showEventType = 2;
                      item.fileStatusCss = 'wating';
                      item.fileStatusStr = '等待转码';
                      item.transform_schedule_str = `等待转码`; // 静态转码完成，动态待转码
                    } else if (status === 100) {
                      item.showEventType = 3;
                      item.fileStatusCss = 'success';
                      item.fileStatusStr = '动态转码中';
                      item.transform_schedule_str = `静态转码成功，动态转码中...`; // 静态转码完成，动态转码中
                    } else if (status === 200) {
                      item.showEventType = 4;
                      item.fileStatusCss = 'success';
                      item.fileStatusStr = '转码成功';
                      item.transform_schedule_str = `静态转码成功<br/>动态转码成功`; // 静态转码完成，动态转码完成
                    } else {
                      item.showEventType = 5;
                      item.fileStatusCss = 'statusfailer';
                      item.fileStatusStr = '转码失败';
                      item.transform_schedule_str = `静态转码成功<br/>动态转码失败`; // 静态转码完成，动态转码失败
                    }
                  } else {
                    // 非PPT静态转码完成
                    item.showEventType = 6;
                    item.fileStatusCss = 'success';
                    item.fileStatusStr = '转码成功';
                    item.transform_schedule_str = `转码成功`; // 静态转码完成，动态转码失败
                  }
                } else if (statusJpeg >= 500) {
                  item.showEventType = 7;
                  item.fileStatusCss = 'failer';
                  item.fileStatusStr = '转码失败';
                  item.transform_schedule_str = `转码失败`; // 静态转码失败
                }
              });
              if (this.pageInfo.pos === 0) {
                this.dialogTableList = res.data.list;
              } else {
                this.dialogTableList.push(...res.data.list);
              }
              if (this.isCheckAll) {
                this.$refs.elTable.toggleAllSelection();
              }
              this.total = res.data.total;
              this.totalPages = Math.ceil(res.data.total / this.pageInfo.limit);
              this.selectedDefaultList(this.selected);
            }
          })
          .catch(e => {
            console.log(e);
          })
          .finally(() => {});
      },
      setRowKeyFun() {},
      searchHandle() {
        // this.dialogMulti = [];
        try {
          this.$refs.elTable.clearSelection();
        } catch (e) {
          console.log(e);
        }
        this.pageInfo.pos = 0;
        this.pageInfo.pageNum = 1;
        this.getDialogTableList();
      },
      // 改变资料库-弹出框内容
      changeDialogCheck(val) {
        this.dialogMulti = val.map(item => item.document_id);
        this.dialogMultiList = val.filter(item => this.dialogMulti.includes(item.document_id));
      },
      checkAllRow(selection) {
        console.log('全选与非全选', selection);
        // 只要数量大于0，即是够了全选
        this.isCheckAll = selection && selection.length > 0;
      },
      initComp() {
        // 历史已经选择过的数据清空
        this.dialogTableList = [];
        this.dialogVisible = true;
        this.formParams.keyword = '';
        this.searchHandle();
      },
      saveCheckHandle() {
        if (
          this.maxCheckedNum > 0 &&
          this.dialogMulti &&
          this.dialogMulti.length > this.maxCheckedNum
        ) {
          this.$vhMessage({
            message: `每次只能添加${this.maxCheckedNum}个文件`,
            showClose: true,
            // duration: 0,
            type: 'error',
            customClass: 'zdy-info-box'
          });
          return;
        }
        this.dialogVisible = false;
        this.$emit('save', this.dialogMultiList);
      },
      cancelCheckHandle() {
        this.dialogMulti = [];
        this.dialogMultiList = [];
        try {
          this.$refs.elTable.clearSelection();
        } catch (e) {
          console.log(e);
        }
        this.dialogVisible = false;
      },
      selectedDefaultList(list) {
        this.dialogMulti = [...list];
        this.$nextTick(() => {
          if (list.length) {
            // alert(JSON.stringify(this.dialogTableList));
            // 如果返回的列表已经选过，就默认选中
            this.dialogTableList.forEach(item => {
              if (list.includes(item.document_id)) {
                this.$nextTick(() => {
                  this.$refs.elTable.toggleRowSelection(item, true);
                });
              }
            });
          }
        });
      }
    },
    mounted() {
      this.$EventBus.$on('host_msg_webinar', res => {
        // 转码状态
        console.log(res, '监听到host_msg_webinar转码状态事件');
        /*
        converted_page: "0" // 动态页数
        converted_page_jpeg: "1" // 静态页数
        document_id: "01b17b82" // 文档ID
        page: "1" // 文档总页数
        status: "200" // 动态转换状态 0待转换 100转换中 200完成 500失败
        status_jpeg: "200" // 静态转换状态 0待转换 100转换中 200完成 500失败
      */
        this.dialogTableList.map(item => {
          if (res.document_id === item.document_id) {
            const statusJpeg = res.status_jpeg * 1;
            const status = res.status * 1;
            if (statusJpeg === 0) {
              item.showEventType = 0;
              item.fileStatusCss = 'wating';
              item.fileStatusStr = '等待转码';
              item.transform_schedule_str = `等待转码`;
            } else if (statusJpeg === 100) {
              item.showEventType = 1;
              item.transform_schedule_str = ``; // 静态转码中
              let _percent = (parseInt(item.converted_page_jpeg) / parseInt(item.page)) * 100;
              item.codeProcess = (_percent + '').substr(0, 4);
            } else if (statusJpeg === 200) {
              if (/pptx?/.test(item.ext)) {
                // 如果是ppt or pptx
                if (status === 0) {
                  item.showEventType = 2;
                  item.fileStatusCss = 'wating';
                  item.fileStatusStr = '等待转码';
                  item.transform_schedule_str = `等待转码`; // 静态转码完成，动态待转码
                } else if (status === 100) {
                  item.showEventType = 3;
                  item.fileStatusCss = 'success';
                  item.fileStatusStr = '动态转码中';
                  item.transform_schedule_str = `静态转码成功，动态转码中...`; // 静态转码完成，动态转码中
                } else if (status === 200) {
                  item.showEventType = 4;
                  item.fileStatusCss = 'success';
                  item.fileStatusStr = '转码成功';
                  item.transform_schedule_str = `静态转码成功<br/>动态转码成功`; // 静态转码完成，动态转码完成
                } else {
                  item.showEventType = 5;
                  item.fileStatusCss = 'statusfailer';
                  item.fileStatusStr = '转码失败';
                  item.transform_schedule_str = `静态转码成功<br/>动态转码失败`; // 静态转码完成，动态转码失败
                }
              } else {
                // 非PPT静态转码完成
                item.showEventType = 6;
                item.fileStatusCss = 'success';
                item.fileStatusStr = '转码成功';
                item.transform_schedule_str = `转码成功`; // 静态转码完成，动态转码失败
              }
            } else if (statusJpeg >= 500) {
              item.showEventType = 7;
              item.fileStatusCss = 'failer';
              item.fileStatusStr = '转码失败';
              item.transform_schedule_str = `转码失败`; // 静态转码失败
            }
            item.page = res.page || '';
          }
        });
      });
    }
  };
</script>

<style lang="less" scoped>
  .word-list {
    padding-bottom: 24px;
    .header-box {
      display: flex;
      justify-content: space-between;
      .upload-btn {
        margin-right: 9px;
      }
    }
    .word-status {
      margin-right: 12px;
    }
    .head-btn {
      margin-bottom: 16px;
    }
    .vh-input {
      width: 220px;
      .vh-input__icon {
        cursor: pointer;
      }
      /deep/ .vh-input__icon {
        line-height: 36px;
      }
    }
    /deep/ .vh-input__inner {
      user-select: none;
      border-radius: 50px;
      font-size: 14px;
      color: #666666;
      height: 36px;
      line-height: 36px;
    }
    .search-tag {
      /deep/.vh-input__inner {
        border-radius: 20px;
        height: 36px;
        padding-right: 30px !important;
      }
      /deep/ .vh-input__suffix {
        cursor: pointer;
      }
    }
  }
  .btn-center {
    margin: 24px auto 0 auto;
    text-align: right;
    .vh-button {
      margin-right: 2px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  /deep/.select-option {
    float: left;
    line-height: 20px;
    margin-top: 8px;
    b {
      color: #fb3a32;
    }
  }
  .word-list {
    /deep/ .vh-table__body .vh-table__row td:nth-child(2) .cell {
      padding-left: 10px;
    }
    /deep/ thead tr th:nth-child(2) .cell {
      padding-left: 10px;
    }
    /deep/.cell .imgs {
      width: 100px;
      height: 100px;
    }
    /deep/.cell .advImg {
      width: 142px;
      height: 80px;
    }
    /deep/.vh-table {
      margin-bottom: 30px;
    }
    /* /deep/.el-table__header{
    background-color: #FB3A32;
  } */
    /deep/.vh-table td:not(:first-child),
    .vh-table th {
      padding: 15px 0;
    }
    /deep/.word-status i.iconfont-v3 {
      font-size: 20px;
    }
    .text {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    /deep/.vh-button.vh-button--text {
      color: #1a1a1a;
      border: 0;
      &:hover {
        color: #fb3a32;
      }
      // /deep/.vh-button.text--default {
      //   margin-right: 20px;
      //   color: #999999;
      //   font-size: 14px;
      //   &:last-child {
      //     margin-right: 0;
      //   }
      //   &:hover {
      //     color: #5d81fb;
      //     &:after {
      //       border-bottom: 1px solid #5d81fb;
      //     }
      //   }
      //   &:active {
      //     color: #3157e1;
      //     &:after {
      //       border-bottom: 1px solid #3157e1;
      //     }
      //   }
      //   &:disabled {
      //     color: #9db3fc;
      //     &:after {
      //       border-bottom: 1px solid #9db3fc;
      //     }
      //   }
      // }
    }
    .status-show {
      span {
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        vertical-align: middle;
        margin-right: 5px;
        margin-top: -3px;
      }
      .active-success {
        background: #14ba6a;
      }
      .active-error {
        background: #fb3a32;
      }
      .active-waiting {
        background: #fa9a32;
      }
    }
    .empty {
      text-align: center;
    }

    .progressBox {
      /deep/ .vh-progress-bar__inner {
        background-color: #14ba6a;
      }
    }
    .statusTag {
      font-size: 14px;
      &::before {
        content: '';
        width: 8px;
        height: 8px;
        border-radius: 50%;
        display: inline-block;
        margin-right: 6px;
      }
      &.wating::before {
        background: #fa9a32;
      }
      &.success::before {
        background: #14ba6a;
      }
      &.failer::before {
        background: #fb3a32;
      }
      &.statusfailer0::before {
        background: #14ba6a;
      }
      &.statusfailer1::before {
        background: #fb3a32;
      }
      .iconContainer {
        padding-left: 10px;
        cursor: pointer;
      }
      /deep/ .saasicon-reset {
        color: #fb3a32;
      }
    }
  }
  .upload_notice {
    line-height: 20px;
    text-align: left;
    margin-top: 16px;
    display: flex;
    justify-content: center;
  }
</style>
