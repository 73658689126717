import { Message } from 'vhall-ui';

let messageInstance = null;
const resetMessage = (options) => {
  if (messageInstance) {
    messageInstance.close()
  }
  if (typeof options === 'string') {
    options = {
      message: options,
      showClose: true,
      customClass: 'zdy-info-box'
    }
  }
  messageInstance = Message(options)
  return messageInstance;
};
['error', 'success', 'info', 'warning'].forEach(type => {
  resetMessage[type] = options => {
    if (typeof options === 'string') {
      options = {
        message: options,
        showClose: true,
        customClass: 'zdy-info-box'
      }
    }
    options.type = type
    return resetMessage(options)
  }
})

export const message = resetMessage